export const i18n_de = {
  lehrgangBookings: {
    summary: {
      title: 'Lehrgangstitel',
      numberOfMandatoryModules: 'Anzahl Pflichtseminare',
      numberOfOptionalModules: 'Zu buchende Wahlseminare (Anzahl)',
      numberOfAllOptionalModules: 'Anzahl Wahlseminare',
      numberOfExams: 'Anzahl Prüfungen',
      numberOfParticipants: 'Anzahl Teilnehmer',
    },
    modulesTable: {
      type: {
        mandatory: 'Pflichtseminare',
        optional: 'Wahlseminare',
        exam: 'Prüfung',
      },
      noModules: 'Keine Seminare vorhanden',
    },
    filters: {
      mandatoryModules: 'Pflichtseminare',
      optionalModules: 'Wahlseminare',
      exams: 'Prüfungen',
    },
    bookingTypes: {
      reservation: 'Reservierung (Prio 60)',
      waitinglist_lehrgang: 'Warteliste (Prio 93)',
      waitinglist: 'Warteliste (Prio 90)',
      lehrgang_module: 'Buchung (Prio 30)',
      standard: 'Buchung (Prio 50)',
    },
    participants: {
      tableHeader: {
        name: 'Name',
        aspId: 'ASP-ID',
        hmgUserId: 'HMGUSERID',
        mandatoryModules: 'Pflichtseminare',
        optionalModules: 'Wahlseminare',
        exam: 'Prüfung',
      },
    },
  },
};
