type EnvironmentSpecificConfig = {
  API_URL: string;
};

const dev: EnvironmentSpecificConfig = {
  API_URL: 'http://localhost:8088/dev',
};
const test: EnvironmentSpecificConfig = {
  API_URL: 'https://nr6r2bdmc9-vpce-0aaa75251ef9131a2.execute-api.eu-central-1.amazonaws.com/test',
};

const prod: EnvironmentSpecificConfig = {
  API_URL: 'https://lksa3j6d2a-vpce-0cc03badf80bdd296.execute-api.eu-central-1.amazonaws.com/prod',
};

const env = process.env.REACT_APP_STAGE || 'dev';

const config = env === 'test' ? test : env === 'prod' ? prod : dev;

const mergedConfig = {
  ...config,
  STANDALONE: env === 'dev',
};

export const MICROFRONTEND_ID = 'lehrgangsbuchungen';

export default mergedConfig;
