import { Table, TableBody, TableCell, Box, TableHead, TableRow, Typography } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { IModuleBooking } from '../../../sharedTypes';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';

interface IModulesTableProps {
  type: 'mandatory' | 'optional' | 'exam';
  modules: IModuleBooking[];
}

export const ModulesTable: FunctionComponent<IModulesTableProps> = ({ modules, type }) => {
  const { t } = useTranslation();

  return (
    <Box style={{ margin: '1rem 1rem 3rem 1rem' }}>
      <Typography
        variant="h5"
        gutterBottom
        component="div"
        style={{ fontSize: '16px', fontWeight: 600, textTransform: 'uppercase', margin: '1.5rem 0' }}
      >
        {t(`lehrgangBookings.modulesTable.type.${type}`)}
      </Typography>
      <Table size="small" aria-label="purchases">
        <TableHead>
          <TableRow>
            <TableCell width={180}>BNR / LOP BNR</TableCell>
            <TableCell width={180}>gebuchte BNR</TableCell>
            <TableCell>Titel (SAP ProduktID)</TableCell>
            <TableCell width={250}>Veranstaltungsdatum</TableCell>
            <TableCell>Buchungspriorität</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {modules.length === 0 ? (
            <TableRow>
              <TableCell colSpan={4}>
                <Typography variant="body1" gutterBottom component="div">
                  {t('lehrgangBookings.modulesTable.noModules')}
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            modules.map((module) => (
              <TableRow key={module.title}>
                <TableCell>{[module.bookingNumber, module.bookingNumberLop].filter(Boolean).join(' / ')}</TableCell>
                <TableCell>{module.booking?.bookingNumber}</TableCell>
                <TableCell>{module.title}</TableCell>
                <TableCell>
                  {[
                    module.booking && module.booking.startDate
                      ? format(new Date(module.booking.startDate), 'dd.MM.yyyy')
                      : undefined,
                    module.booking && module.booking.endDate
                      ? format(new Date(module.booking.endDate), 'dd.MM.yyyy')
                      : undefined,
                  ]
                    .filter(Boolean)
                    .join(' - ')}
                </TableCell>
                <TableCell>
                  {t(`lehrgangBookings.bookingTypes.${module.booking?.bookingType}`, {
                    defaultValue: module.booking?.bookingType || '',
                  })}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </Box>
  );
};
