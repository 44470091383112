import ReactDOM from 'react-dom';
import { IMicroFrontendInstance } from '@hmg-aka-lu/cockpit-frontend/dist-types/exports';

import './i18n';

import './theme/FontFace.css';
import LightHouseThemeProvider from './theme/LightHouseThemeProvider';
import mergedConfig, { MICROFRONTEND_ID } from './config';
import JWTContext from './components/JWTContext';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { LehrgangOverview } from './pages/LehrgangOverview';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ParticipantOverview } from './pages/ParticipantOverview';
declare global {
  interface Window {
    [MICROFRONTEND_ID]: IMicroFrontendInstance;
  }
}

const microFrontendInstance: IMicroFrontendInstance = (containerId = 'root', history: any, microfrontendMeta) => {
  const queryClient = new QueryClient();

  return {
    render: () => {
      ReactDOM.render(
        <LightHouseThemeProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <JWTContext.Provider value={{ getJWT: microfrontendMeta.getJWT }}>
              <BrowserRouter>
                <Routes>
                  <Route path={`/${MICROFRONTEND_ID}/search`} element={<LehrgangOverview />} />
                  <Route path={`/${MICROFRONTEND_ID}/user/search`} element={<ParticipantOverview />} />
                  <Route path="*" element={<Navigate replace to={`/${MICROFRONTEND_ID}/search`} />} />
                </Routes>
              </BrowserRouter>
            </JWTContext.Provider>
          </QueryClientProvider>
        </LightHouseThemeProvider>,
        document.getElementById(containerId)
      );
    },
  };
};

window.lehrgangsbuchungen = microFrontendInstance;

if (mergedConfig.STANDALONE) {
  const microFrontend = microFrontendInstance(
    'root',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    undefined,
    {
      getJWT: async () => {
        return '';
      },
      hasCockpitAccess: () => true,
      hasRequiredGroup: () => true,
      authenticatedContext: {},
      relativeUrl: '12312',
    }
  );
  microFrontend.render();
}
