import { TableContainer, Paper, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { ILehrgangSummary } from '../../../../sharedTypes';
import { useTranslation } from 'react-i18next';

interface ILehrgangSummaryProps {
  summary: ILehrgangSummary;
}

const row = (key: string, value: string | number) => (
  <TableRow>
    <TableCell component="th" scope="row">
      <strong>{key}</strong>
    </TableCell>
    <TableCell align="right">{value}</TableCell>
  </TableRow>
);

export const LehrgangSummary: FunctionComponent<ILehrgangSummaryProps> = ({ summary }) => {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableBody>
          {row(t('lehrgangBookings.summary.title'), summary.title)}
          {row(t('lehrgangBookings.summary.numberOfMandatoryModules'), summary.numberOfMandatoryModules)}
          {row(t('lehrgangBookings.summary.numberOfAllOptionalModules'), summary.optionalModules.length ?? 0)}
          {row(t('lehrgangBookings.summary.numberOfOptionalModules'), summary.numberOfOptionalModules ?? 0)}
          {row(t('lehrgangBookings.summary.numberOfExams'), summary.numberOfExams)}
          {row(t('lehrgangBookings.summary.numberOfParticipants'), summary.numberOfParticipants)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
