import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  Collapse,
  IconButton,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { FunctionComponent, useState } from 'react';
import { ILehrgangData, IParticipant } from '../../../../sharedTypes';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React from 'react';
import { ModulesTable } from '../ModulesTable';

interface IParticipantsTableProps {
  participants: IParticipant[];
  lehrgang: ILehrgangData;
}

interface IRowProps {
  participant: IParticipant;
  lehrgang: ILehrgangData;
}

const Row: FunctionComponent<IRowProps> = ({ participant, lehrgang }) => {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{[participant.title ?? '', participant.firstName, participant.lastName].join(' ')}</TableCell>
        <TableCell>{participant.sapUserId}</TableCell>
        <TableCell component="th" scope="row">
          {participant.hmguserid}
        </TableCell>
        <TableCell>
          {participant.mandatoryModules.filter((module) => module.booking).length} von{' '}
          {participant.mandatoryModules.length}
        </TableCell>
        <TableCell>
          {participant.optionalModules.filter((module) => module.booking).length} von {lehrgang.numberOfOptionalModules}
        </TableCell>
        <TableCell>
          {participant.exams.filter((module) => module.booking).length} von {participant.exams.length}
        </TableCell>
      </TableRow>
      <TableRow style={{ background: '#f7f7f8' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: '65px' }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ModulesTable type="mandatory" modules={participant.mandatoryModules} />
            <ModulesTable type="optional" modules={participant.optionalModules} />
            <ModulesTable type="exam" modules={participant.exams} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export const ParticipantsTable: FunctionComponent<IParticipantsTableProps> = ({ participants, lehrgang }) => {
  return (
    <TableContainer component={Paper} style={{ marginBottom: '3rem' }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell width={20} />
            <TableCell>Name</TableCell>
            <TableCell>ASP-ID</TableCell>
            <TableCell>HMGUSERID</TableCell>
            <TableCell>Pflichtseminare</TableCell>
            <TableCell>Wahlseminare</TableCell>
            <TableCell>Prüfung</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {participants.length === 0 ? (
            <TableRow>
              <TableCell width={60} />
              <TableCell colSpan={8}>Keine Teilnehmer gefunden</TableCell>
            </TableRow>
          ) : (
            participants.map((row) => (
              <Row key={`${row.title}-${row.hmguserid}`} participant={row} lehrgang={lehrgang} />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
