import { ILehrgangDataResponse, IUserLehrgangDataResponse } from '../../../sharedTypes';
import mergedConfig from '../config';

export const getApiService = (getJWT: () => Promise<string | undefined | null>) => {
  const apiRequest = async (
    path: string,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    body?: BodyInit,
    params?: Record<string, string>
  ) => {
    const jwt = await getJWT();
    if (!jwt) {
      throw new Error('Could not get JWT');
    }

    const result = await fetch(`${mergedConfig.API_URL}/${path}?${new URLSearchParams(params)}`, {
      method,
      body,
      headers: {
        'Content-Type': 'application/json',
        authorization: jwt ?? '',
      },
    });

    if (result.status >= 400) {
      const data = await result.json();
      throw new Error(data.message);
    }

    return await result.text();
  };

  const getLehrgang = async (lehrgangId: string): Promise<ILehrgangDataResponse> => {
    const result = await apiRequest(`lehrgang/${lehrgangId}`, 'GET');

    return JSON.parse(result) as unknown as ILehrgangDataResponse;
  };

  const getUserLehrgang = async (userId: string): Promise<IUserLehrgangDataResponse> => {
    const result = await apiRequest(`user/${userId}/lehrgaenge`, 'GET');

    return JSON.parse(result) as unknown as IUserLehrgangDataResponse;
  };

  return {
    getLehrgang,
    getUserLehrgang,
  };
};
