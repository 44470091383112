import { format } from 'date-fns';
import { ILehrgangData, IParticipant, IUserData } from '../../sharedTypes';

export const downloadAsFile = (data: string, filename: string, options?: FilePropertyBag) => {
  const file = new Blob(['\ufeff' + data], options);
  const url = URL.createObjectURL(file);
  const downloadLink = document.createElement('a');
  downloadLink.setAttribute('href', url);
  downloadLink.setAttribute('download', filename);
  downloadLink.style.visibility = 'hidden';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(url);
};

const headers = [
  'Lehrgangstitel',
  'Lehrgangs-ID',
  'HGSP ID',
  'ASP ID',
  'Name',
  'Anzahl Pflichtseminare',
  'zu buchende Wahlseminare (Anzahl)',
  'Anzahl Prüfung',
  'Lehrgangsbestandteil (BNR)',
  'hat LOP (BNR)',
  'Lehrgangsbestandteil (Titel)',
  'Ist Pflicht',
  'Ist Wahl',
  'Ist Prüfung',
  'bereits gebucht',
  'gebuchte BNR',
  'Veranstaltungsdatum',
  'Buchungspriorität',
];

export const exportLehrgaengeAsCSV = (lehrgaenge: ILehrgangData[], userData: IUserData) => {
  const rows: string[] = [];
  rows.push(headers.join(';'));

  lehrgaenge.forEach((lehrgang) => {
    lehrgang.mandatoryModules.concat(lehrgang.optionalModules, lehrgang.exams).forEach((module) => {
      const isMandatory = lehrgang.mandatoryModules.includes(module);
      const isOptional = lehrgang.optionalModules.includes(module);
      const isExam = lehrgang.exams.includes(module);

      const data = [
        lehrgang.title,
        lehrgang.id,
        userData.hmguserid,
        userData.sapUserId,
        `${userData.firstName} ${userData.lastName}`,
        lehrgang.numberOfMandatoryModules,
        lehrgang.numberOfOptionalModules,
        lehrgang.numberOfExams,
        module.bookingNumber,
        module.bookingNumberLop,
        module.title,
        isMandatory ? 'Ja' : 'Nein',
        isOptional ? 'Ja' : 'Nein',
        isExam ? 'Ja' : 'Nein',
        module.booking ? 'Ja' : 'Nein',
        module.booking?.bookingNumber,
        [
          module.booking?.startDate ? format(new Date(module.booking.startDate), 'dd.MM.yyyy') : '',
          module.booking?.endDate ? format(new Date(module.booking.endDate), 'dd.MM.yyyy') : '',
        ]
          .filter(Boolean)
          .join(' - '),
        module.booking ? getBookingType(module.booking?.bookingType) : '',
      ];
      rows.push(data.join(';'));
    });
  });

  downloadAsFile(rows.join('\n'), 'exportLehrgaenge', { type: 'text/csv' });
};

const getBookingType = (bookingType: string) => {
  switch (bookingType) {
    case 'reservation':
      return 'Reservierung (Prio 60)';
    case 'waitinglist_lehrgang':
      return 'Warteliste (Prio 93)';
    case 'waitinglist':
      return 'Warteliste (Prio 90)';
    case 'lehrgang_module':
      return 'Buchung (Prio 30)';
    case 'standard':
      return 'Buchung (Prio 50)';
    default:
      return bookingType;
  }
};

export const exportParticipantsAsCSV = (lehrgang: ILehrgangData, participants: IParticipant[]) => {
  const rows: string[] = [];
  rows.push(headers.join(';'));

  participants.forEach((participant) => {
    participant.mandatoryModules.concat(participant.optionalModules, participant.exams).forEach((module) => {
      const isMandatory = participant.mandatoryModules.includes(module);
      const isOptional = participant.optionalModules.includes(module);
      const isExam = participant.exams.includes(module);

      const data = [
        lehrgang.title,
        lehrgang.id,
        participant.hmguserid,
        participant.sapUserId,
        `${participant.firstName} ${participant.lastName}`,
        lehrgang.numberOfMandatoryModules,
        lehrgang.numberOfOptionalModules,
        lehrgang.numberOfExams,
        module.bookingNumber,
        module.bookingNumberLop,
        module.title,
        isMandatory ? 'Ja' : 'Nein',
        isOptional ? 'Ja' : 'Nein',
        isExam ? 'Ja' : 'Nein',
        module.booking ? 'Ja' : 'Nein',
        module.booking?.bookingNumber,
        [
          module.booking?.startDate ? format(new Date(module.booking.startDate), 'dd.MM.yyyy') : '',
          module.booking?.endDate ? format(new Date(module.booking.endDate), 'dd.MM.yyyy') : '',
        ]
          .filter(Boolean)
          .join(' - '),
        module.booking ? getBookingType(module.booking?.bookingType) : '',
      ];
      rows.push(data.join(';'));
    });
  });

  downloadAsFile(rows.join('\n'), 'exportParticipant', { type: 'text/csv' });
};
