import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
} from '@material-ui/core';
import { FunctionComponent, useState } from 'react';
import { ILehrgangData } from '../../../../sharedTypes';
import React from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { ModulesTable } from '../ModulesTable';

interface ILehrgaengeTableProps {
  lehrgaenge: ILehrgangData[];
}

interface IRowProps {
  lehrgang: ILehrgangData;
}

const Row: FunctionComponent<IRowProps> = ({ lehrgang }) => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{lehrgang.id}</TableCell>
        <TableCell>{lehrgang.title}</TableCell>
        <TableCell>
          {lehrgang.mandatoryModules.filter((module) => module.booking).length} von {lehrgang.mandatoryModules.length}
        </TableCell>
        <TableCell>
          {lehrgang.optionalModules.filter((module) => module.booking).length} von {lehrgang.numberOfOptionalModules}
        </TableCell>
        <TableCell>
          {lehrgang.exams.filter((module) => module.booking).length} von {lehrgang.exams.length}
        </TableCell>
      </TableRow>
      <TableRow style={{ background: '#f7f7f8' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: '65px' }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ModulesTable type="mandatory" modules={lehrgang.mandatoryModules} />
            <ModulesTable type="optional" modules={lehrgang.optionalModules} />
            <ModulesTable type="exam" modules={lehrgang.exams} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export const LehrgaengeTable: FunctionComponent<ILehrgaengeTableProps> = ({ lehrgaenge }) => {
  return (
    <TableContainer component={Paper} style={{ margin: '3rem 0' }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell width={20} />
            <TableCell>Lehrgang ID</TableCell>
            <TableCell>Titel</TableCell>
            <TableCell>Pflichtseminare</TableCell>
            <TableCell>Wahlseminare</TableCell>
            <TableCell>Prüfung</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lehrgaenge.length === 0 ? (
            <TableRow>
              <TableCell width={60} />
              <TableCell colSpan={6}>Keine Lehrgänge gefunden</TableCell>
            </TableRow>
          ) : (
            lehrgaenge.map((row) => <Row key={row.title} lehrgang={row} />)
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
