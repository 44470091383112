import { FunctionComponent, useContext, useState } from 'react';
import { Form } from '../components/Form';
import { Box, Grid, LinearProgress, Typography } from '@material-ui/core';
import { LehrgangSummary } from '../components/LehrgangBookings/LehrgangSummary';
import { getApiService } from '../api/api-request';
import JWTContext from '../components/JWTContext';
import { ILehrgangDataResponse, ILehrgangFilters, IParticipant } from '../../../sharedTypes';
import { ParticipantsTable } from '../components/LehrgangBookings/ParticipantsTable';
import { Filters } from '../components/LehrgangBookings/Filters';
import { exportParticipantsAsCSV } from '../utils';
import { Alert } from '@material-ui/lab';

export const LehrgangOverview: FunctionComponent = () => {
  const { getJWT } = useContext(JWTContext);
  const apiService = getApiService(getJWT);

  const [data, setData] = useState<ILehrgangDataResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [filters, setFilters] = useState<ILehrgangFilters>({
    query: '',
    booked: [],
    notBooked: [],
  });

  const loadLehrgangData = async (lehrgangId: string) => {
    setDisplayError(false);
    setLoading(true);
    try {
      const response = await apiService.getLehrgang(lehrgangId);
      setData(response);
    } catch (error) {
      setDisplayError(true);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChangeQuery = (query: string) => {
    setFilters((prev) => ({
      ...prev,
      query,
    }));
  };

  const handleFilterChangeBooked = (list: string[]) => {
    setFilters((prev) => ({
      ...prev,
      booked: list,
    }));
  };

  const handleFilterChangeNotBooked = (list: string[]) => {
    setFilters((prev) => ({
      ...prev,
      notBooked: list,
    }));
  };

  const getAllModules = () => {
    const moduleTitles: {
      group: string;
      title: string;
    }[] = [];

    if (data?.lehrgang) {
      for (const module of data.lehrgang.mandatoryModules) {
        moduleTitles.push({ title: module.title, group: 'lehrgangBookings.filters.mandatoryModules' });
      }

      for (const module of data.lehrgang.optionalModules) {
        moduleTitles.push({ title: module.title, group: 'lehrgangBookings.filters.optionalModules' });
      }

      for (const module of data.lehrgang.exams) {
        moduleTitles.push({ title: module.title, group: 'lehrgangBookings.filters.exams' });
      }
    }

    return moduleTitles;
  };

  const filteredParticipants = () => {
    if (!data) {
      return [];
    }

    const participants: IParticipant[] = data.participants
      .filter((p) => {
        if (filters.query !== '') {
          return `${p.hmguserid} ${p.sapUserId} ${p.title} ${p.firstName} ${p.lastName}`
            .toLowerCase()
            .includes(filters.query.toLowerCase());
        }
        return true;
      })
      .filter((p) => {
        if (filters.booked.length > 0) {
          for (const module of p.mandatoryModules) {
            if (filters.booked.includes(module.title) && module.booking) {
              return true;
            }
          }

          for (const module of p.optionalModules) {
            if (filters.booked.includes(module.title) && module.booking) {
              return true;
            }
          }

          for (const module of p.exams) {
            if (filters.booked.includes(module.title) && module.booking) {
              return true;
            }
          }

          return false;
        }
        return true;
      })
      .filter((p) => {
        if (filters.notBooked.length > 0) {
          for (const module of p.mandatoryModules) {
            if (filters.notBooked.includes(module.title) && !module.booking) {
              return true;
            }
          }

          for (const module of p.optionalModules) {
            if (filters.notBooked.includes(module.title) && !module.booking) {
              return true;
            }
          }

          for (const module of p.exams) {
            if (filters.notBooked.includes(module.title) && !module.booking) {
              return true;
            }
          }

          return false;
        }
        return true;
      });
    return participants;
  };

  const exportData = () => {
    if (data?.participants) {
      exportParticipantsAsCSV(data.lehrgang, filteredParticipants());
    }
  };

  return (
    <Box mt={2}>
      <Typography variant="h1" component="h2" className="m-4">
        Lehrgangssuche
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Form placeholder="Lehrgang ID" onDataFetch={loadLehrgangData} onExportData={exportData} />
        </Grid>
        <Grid item xs={4}>
          {data && (
            <LehrgangSummary
              summary={{
                ...data.lehrgang,
                numberOfParticipants: data.participants.length,
              }}
            />
          )}
        </Grid>
      </Grid>

      {loading && <LinearProgress style={{ margin: '2rem 0' }} />}
      {displayError && (
        <Alert severity="error" style={{ margin: '2rem 0' }}>
          Lehrgang nicht gefunden
        </Alert>
      )}
      {data && (
        <Filters
          list={getAllModules()}
          onQueryChange={handleFilterChangeQuery}
          onBookedChange={handleFilterChangeBooked}
          onNotBookedChange={handleFilterChangeNotBooked}
        />
      )}
      {data && <ParticipantsTable participants={filteredParticipants()} lehrgang={data.lehrgang} />}
    </Box>
  );
};
