import { FunctionComponent, useContext, useState } from 'react';
import { Form } from '../components/Form';
import { Box, Grid, LinearProgress, Typography } from '@material-ui/core';
import { getApiService } from '../api/api-request';
import JWTContext from '../components/JWTContext';
import { IUserLehrgangDataResponse } from '../../../sharedTypes';
import { ParticipantSummary } from '../components/ParticipantBookings/ParticipantSummary';
import { LehrgaengeTable } from '../components/ParticipantBookings/LehrgaengeTable';
import { exportLehrgaengeAsCSV } from '../utils';

export const ParticipantOverview: FunctionComponent = () => {
  const { getJWT } = useContext(JWTContext);
  const apiService = getApiService(getJWT);

  const [data, setData] = useState<IUserLehrgangDataResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const loadParticipantData = async (userId: string) => {
    setLoading(true);
    const response = await apiService.getUserLehrgang(userId);
    setData(response);
    setLoading(false);
  };

  const exportData = () => {
    if (data?.lehrgang) {
      exportLehrgaengeAsCSV(data.lehrgang, data.userData);
    }
  };

  return (
    <Box mt={2}>
      <Typography variant="h1" component="h2" className="m-4">
        TeilnehmerInnensuche
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Form placeholder="HMGUSER ID" onDataFetch={loadParticipantData} onExportData={exportData} />
        </Grid>
        <Grid item xs={4}>
          {data && <ParticipantSummary participant={data.userData} />}
        </Grid>
      </Grid>
      {loading && <LinearProgress style={{ margin: '2rem 0' }} />}
      {data && <LehrgaengeTable lehrgaenge={data.lehrgang} />}
    </Box>
  );
};
