import { TableContainer, Paper, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { IUserData } from '../../../../sharedTypes';

interface ILehrgangSummaryProps {
  participant: IUserData;
}

const row = (key: string, value: string | number) => (
  <TableRow>
    <TableCell component="th" scope="row">
      <strong>{key}</strong>
    </TableCell>
    <TableCell align="right">{value}</TableCell>
  </TableRow>
);

export const ParticipantSummary: FunctionComponent<ILehrgangSummaryProps> = ({ participant }) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableBody>
          {row('Name', `${participant.firstName} ${participant.lastName}`)}
          {row('ASP ID', participant.sapUserId ?? '')}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
