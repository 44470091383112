import { Box, Button, Input } from '@material-ui/core';
import { FunctionComponent, useState } from 'react';

interface IFormProps {
  placeholder: string;
  onDataFetch: (lehrgangId: string) => void;
  onExportData?: () => void;
}

export const Form: FunctionComponent<IFormProps> = ({ onDataFetch, placeholder, onExportData }) => {
  const [lehrgangId, setLehrgangId] = useState<string>('');

  return (
    <Box display="flex">
      <Input
        placeholder={placeholder}
        value={lehrgangId}
        onChange={(e) => setLehrgangId(e.target.value)}
        style={{ minWidth: 400 }}
      />
      <Button
        color="primary"
        variant="contained"
        disabled={!lehrgangId}
        onClick={() => onDataFetch(lehrgangId)}
        style={{ margin: '0 1rem' }}
      >
        Suchen
      </Button>

      <Button color="primary" variant="outlined" onClick={onExportData}>
        Export
      </Button>
    </Box>
  );
};
