import { Box, Checkbox, Paper, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { FunctionComponent } from 'react';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useTranslation } from 'react-i18next';

interface IFormProps {
  list: {
    group: string;
    title: string;
  }[];
  onQueryChange: (query: string) => void;
  onBookedChange: (list: string[]) => void;
  onNotBookedChange: (list: string[]) => void;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const Filters: FunctionComponent<IFormProps> = ({ onQueryChange, onBookedChange, onNotBookedChange, list }) => {
  const { t } = useTranslation();

  return (
    <Box component={Paper} display="flex" alignItems="flex-start" py={3} pl={9} pr={2} mt={4}>
      <TextField
        size="small"
        placeholder="Suche (Name, ASP-ID, HMGUSERID)"
        variant="outlined"
        onChange={(e) => onQueryChange(e.target.value)}
        style={{ minWidth: 400 }}
      />

      <Autocomplete
        multiple
        size="small"
        id="checkboxes-tags-demo"
        options={list}
        disableCloseOnSelect
        getOptionLabel={(option) => option.title}
        groupBy={(option) => t(option.group)}
        onChange={(e, value) => onBookedChange(value.map((v) => v.title))}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option.title}
          </React.Fragment>
        )}
        style={{ width: '100%', margin: '0 2rem' }}
        renderInput={(params) => <TextField {...params} variant="outlined" label="gebuchte Module" />}
      />

      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={list}
        size="small"
        disableCloseOnSelect
        groupBy={(option) => t(option.group)}
        getOptionLabel={(option) => option.title}
        onChange={(e, value) => onNotBookedChange(value.map((v) => v.title))}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option.title}
          </React.Fragment>
        )}
        style={{ width: '100%' }}
        renderInput={(params) => <TextField {...params} variant="outlined" label="nicht gebuchte Module" />}
      />
    </Box>
  );
};
